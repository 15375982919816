*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container{
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}